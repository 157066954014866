import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import ThemeStyler from '../components/pages/_app/ThemeStyler';
import CustomJS from '../components/pages/_app/CustomJS';

// Disable lint because swiper didn't provide a CJS implementation for backward compatibility
// eslint-disable-next-line import/no-unresolved
import 'swiper/scss';

import '../shared/css/main.scss';
import { setLang } from '../helpers/lang';
import { setCurrency } from '../helpers/formatPrice';
import { settingsProps } from '../helpers/propTypes';
import RollbarLogger from '../helpers/RollbarLogger';
import BlockShop from '../components/pages/index/BlockShop';
import WarMessage from '../components/pages/_app/WarMessage';
import { SettingsProvider } from '../components/Context';
import { NavigationProvider } from '../hooks/useNavigationPosition';
import ErrorBoundary from '../components/error/ErrorBoundary';

RollbarLogger.init();

function B2CApp({ Component, pageProps, router }) {
  let { settings } = pageProps;

  if (!settings || Object.keys(settings).length === 0) {
    settings = {};
  } else {
    setLang(settings.siteLang);
    setCurrency(settings.currency, settings.lang);
  }
  const getLayout = Component.getLayout || ((page) => page);

  if (settings?.country === 'RU' || settings?.country === 'BY') {
    return <WarMessage />;
  }

  return (
    <ErrorBoundary>
      <NavigationProvider>
        <SettingsProvider value={settings}>
          <Head>
            {Boolean(settings.favicon) && (
              <link rel="icon" href={settings.favicon} />
            )}
            {Boolean(settings.customCssDelivery) && <style type="text/css">{settings.customCssDelivery}</style>}
          </Head>

          <ThemeStyler
            defaultThemeId={settings.deliveryColorTheme || 0}
            defaultAccentHex={settings.deliveryColor || undefined}
          />
          <CustomJS customJS={settings.customJS} router={router} />

          {settings.blockShop ? (
            <BlockShop
              logo={settings.logo}
              name={settings.shopName}
              phone={settings.phone}
              email={settings.email}
            />
          ) : (
            getLayout(<Component {...pageProps} router={router} />, pageProps, router)
          )}
        </SettingsProvider>
      </NavigationProvider>
    </ErrorBoundary>
  );
}

B2CApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  pageProps: PropTypes.shape({
    settings: settingsProps,
  }).isRequired,
  router: PropTypes.shape({}).isRequired,
};

export default B2CApp;
